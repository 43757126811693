:global(
    .chameleon
      .bluebook-player-inner-container
      .popover__content:has(.toolbar-more-items-popup)
  ) {
  padding: 0 !important;
  border-radius: 2px !important;
  border: 1px solid var(--color-bluebook-gray2) !important;
  min-width: auto;
}
